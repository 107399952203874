import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@chakra-ui/react';
import _ from 'lodash';
import moment from 'moment';
import Spinners from '../../../../components/Loading/SpinnerLoading';
import * as actions from '../../../../redux/actions';
import { DEPART_INFO_TAB } from '../../../../config/WordUtil';
import DepartmentPresentTask from '../../../../components/Department/DepartmentPresentTask';
import TaskStatictic from '../../../../components/Department/TaskStatistic';
import DateRangePicker from '@wojtekmaj/react-daterange-picker/dist/DateRangePicker';
export default function HistoryOnEachDepartment() {
  // Variable Define
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const param = useParams();
  const task = useSelector((state) => state.task);
  const department = useSelector((state) => state.department);
  const [selectedRange, setSelectedRange] = useState([
    moment().subtract(1, 'months').toDate(),
    moment().toDate(),
  ]);
  const [selectedDepartment, setSelectedDepartment] = useState();
  const [pageTabStatus, setPageTabStatus] = useState(DEPART_INFO_TAB.PRESENT);

  useEffect(() => {
    const departmentId = param.id;
    const tempSelectedDepartment = _.find(
      department,
      (eachDepartment) => eachDepartment.department_id === departmentId,
    );
    if (tempSelectedDepartment) {
      setSelectedDepartment(tempSelectedDepartment);
    }
    return () => {};
  }, [param]);
  console.log("selectedRange[0]", selectedRange[0]);
  console.log("selectedRange[1]", selectedRange[1]);
 

  // Get Selected Task Data
  useEffect(() => {
    if (selectedDepartment) {
      dispatch(
        actions.taskAllFromDepartment(selectedDepartment.department_id ,{start:moment(selectedRange?.[0]).format('YYYY-MM-DD'), end:moment(selectedRange?.[1]).format('YYYY-MM-DD')} ),
      ).then(() => {
        setIsLoading(true);
      });
    }

    return () => {};
  }, [selectedDepartment, selectedRange]);

  // UI Rendering
  if (isLoading) {
    return (
      <div>
        <h2 className="text-gray-800 text-xl font-semibold py-1">
          สถานะงานในแผนก {selectedDepartment.department_name}
        </h2>

        <div className="py-4 md:py-6">
          <Link to="/">
            <Button variant="outline">กลับ</Button>
          </Link>
          <Button
            variant="outline"
            colorScheme={
              pageTabStatus === DEPART_INFO_TAB.PRESENT ? 'blue' : 'gray'
            }
            onClick={() => setPageTabStatus(DEPART_INFO_TAB.PRESENT)}
          >
            สถานะงานตอนนี้
          </Button>
          <Button
            variant="outline"
            colorScheme={
              pageTabStatus === DEPART_INFO_TAB.STATISTIC ? 'blue' : 'gray'
            }
            onClick={() => setPageTabStatus(DEPART_INFO_TAB.STATISTIC)}
          >
            สถิติงาน
          </Button>
        </div>
        <div>
        <div className='m-4 flex justify-end '>
          <div  className='mr-2'>วันและเวลา</div>
             {isLoading ? <DateRangePicker
                value={selectedRange}
                onChange={setSelectedRange}
                clearIcon={null}
              />:<Spinners/>}
            </div>        
        </div>
        <div className="p-4">
          {pageTabStatus === DEPART_INFO_TAB.PRESENT && (
            <DepartmentPresentTask allTask={task.arr} />
          )}
          {pageTabStatus === DEPART_INFO_TAB.STATISTIC && (
            <TaskStatictic
              selectedRange={selectedRange}
              allTask={task.arr}
              departmentId={selectedDepartment.department_id}
            />
          )}
        </div>
      </div>
    );
  }
  return <Spinners />;
}
